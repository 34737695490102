<template>
    <div class="form-group" :class="[{ 'form-group--has-m-b': hasMarginBottom }]">
        <div
            class="color-picker form-field"
            :class="{ 'form-field--has-error': errors.length > 0 }"
        >
            <label class="form-field-label">
                <span>{{ label }}</span>
            </label>

            <div class="color-picker-wrapper">
                <div v-for="(colorHex, index) in colorPalette" :key="index" class="color-container">
                    <template v-if="alreadyInUse.includes(colorHex)">
                        <input
                            :id="`radio-${colorHex}-${index}`"
                            class="color-radio"
                            :value="colorHex"
                            type="radio"
                        />
                        <label :for="`radio-${colorHex}-${index}`">
                            <span class="color-palette disabled" :style="{ background: colorHex }">
                                <Icon name="colour-disabled" />
                            </span>
                        </label>
                    </template>
                    <template v-else>
                        <input
                            :id="`radio-${colorHex}-${index}`"
                            v-model="pickedColor"
                            class="color-radio"
                            :value="colorHex"
                            type="radio"
                        />
                        <label :for="`radio-${colorHex}-${index}`">
                            <span
                                :class="{
                                    checked: pickedColor === colorHex,
                                }"
                                class="color-palette"
                                :style="{ background: colorHex }"
                                @click="clicked(colorHex)"
                            >
                                <Icon v-if="pickedColor === colorHex" name="colour-picker-check" />
                            </span>
                        </label>
                    </template>
                </div>

                <div class="color-container color-picker-custom">
                    <input
                        :id="`radio-${colorPalette.length}`"
                        v-model="pickedColor"
                        class="color-radio"
                        type="radio"
                    />
                    <label :for="`radio-${colorPalette.length}`">
                        <span
                            :class="{ checked: customColor && pickedColor === customColor }"
                            class="color-palette"
                            @click="toggleColorPalette"
                        >
                            <Icon name="plus-2" />
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div class="form-field-additional-information">
            <div class="form-field-additional-information__left">
                <div v-if="errors && errors.length > 0" class="form-field-error-message">
                    <Icon name="alert" />
                    {{ errors[0] }}
                </div>
            </div>
        </div>

        <template v-if="type === 'chrome'">
            <Chrome v-show="showColorPalette" :value="colors" @input="(value) => onInput(value)" />
        </template>
        <template v-else>
            <Slider v-show="showColorPalette" :value="colors" @input="(value) => onInput(value)" />
        </template>
    </div>
</template>

<script>
import Slider from 'vue-color/src/components/Slider';
import Chrome from 'vue-color/src/components/Chrome';
import Icon from '@/components/ui/Icon';

export default {
    name: 'ColorPicker',
    components: {
        Chrome,
        Slider,
        Icon,
    },
    props: {
        value: {
            type: String,
            default: undefined,
        },
        defaultColor: {
            type: String,
            default: undefined,
        },
        type: {
            type: String,
            default: 'slider',
        },
        label: {
            type: String,
            default: 'Color Picker',
        },
        hasMarginBottom: {
            type: Boolean,
            default: true,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        colorOptions: {
            type: Array,
            default: () => ['#fbcc7f', '#f9af7d', '#f17f73', '#a683de', '#6d8ade', '#34c5d2'],
        },
        alreadyInUse: {
            type: Array,
            default: () => [],
        },
        maxColors: {
            type: Number,
            default: 6,
        },
    },
    data() {
        return {
            colors: {
                rgba: { r: 251, g: 204, b: 127, a: 0.4 },
            },
            pickedColor: undefined,
            customColor: '',
            showColorPalette: false,
            colorPalette: [],
        };
    },
    watch: {
        value(n, o) {
            if (!o) {
                this.setDefaultColors();
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.setDefaultColors();
        });
    },
    methods: {
        clicked(value) {
            this.pickedColor = value;
            if (this.showColorPalette) {
                this.showColorPalette = false;
            }
            this.$emit('input', this.pickedColor);
        },
        onInput(value) {
            this.customColor = value.hex;
            this.pickedColor = value.hex;
            this.$emit('input', this.pickedColor);
        },
        toggleColorPalette() {
            if (this.showColorPalette && this.customColor) {
                this.colorPalette = this.getUniqueSetOfColors(this.customColor, this.colorPalette);
            } else {
                this.$emit('input', '');
            }
            this.customColor = '';
            this.showColorPalette = !this.showColorPalette;
        },
        setDefaultColors() {
            let colors = this.colorOptions;
            this.colorPalette = this.getUniqueSetOfColors(this.value, colors);
            this.pickedColor = this.value || this.defaultColor;
        },
        getUniqueSetOfColors(selectedColor, colors) {
            if (colors && colors.length) {
                colors = [...new Set(colors)];
                colors = colors.slice(-this.maxColors);
                if (selectedColor && !colors.includes(selectedColor)) {
                    colors.push(this.value);
                    colors = colors.slice(-this.maxColors);
                }
                return colors;
            }
            return;
        },
    },
};
</script>

<style lang="scss" scoped>
.form-group {
    &--has-m-b {
        margin-bottom: $form-group-margin-bottom;
    }
}
.color-picker {
    &-custom {
        .color-palette {
            border: 1px solid $color-athens-gray;
            font-size: 18px;
            color: $alternate-text-color;
        }
    }
    &-wrapper {
        padding: 11px 3px 9px;
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: scroll;
        display: flex;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    .color-palette {
        $size: 20px;
        height: $size;
        width: $size;
        border-radius: $size;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
            border: 1px solid $color-palette-hover-border;
        }
    }
    .color-container {
        display: block;
        padding: 0 8px;

        label {
            display: block;
        }
    }
    .color-radio {
        display: none;
    }
    .checked {
        font-size: 19px;
        color: $default-light-color;
    }
    .disabled {
        font-size: 19px;
        color: $default-light-color;
        opacity: 0.4;
        pointer-events: none;
        cursor: none;
    }
}
</style>
<style lang="scss">
.vc-slider {
    width: 55% !important; //library has hardcoded width of 410px
}
</style>
